@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles.css */
body {
  background-color: #283140; /* Dark background color */
  color: #edf2f7; /* Light text color */
}

#root {
  background-color: #283140; /* Slightly lighter background for components */
  padding: 1rem;
  border-radius: 0.5rem; /* Rounded corners for components */
}
